import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/css/fonts.css'
import 'primeicons/primeicons.css';
import VWave from 'v-wave'


const app = createApp(App)

app.use(VWave)
app.use(router).mount('#app')
