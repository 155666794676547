import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'ArtistHome',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/home/ArtistHome.vue')
  },
  {
    path: '/past-fourty-eight',
    name: 'PastFourtyEight',
    component: () => import(/* webpackChunkName: "book" */ '@/pages/books/PastFourtyEight.vue')
  },
  {
    path: '/about',
    name: 'AboutNarek',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/about/AboutNarek.vue')
  },
  {
    path: '/songs',
    name: 'NarekSongs',
    component: () => import(/* webpackChunkName: "songs" */ '@/pages/songs/NarekSongs.vue')
  },
  {
    path: '/admin/add-song',
    name: 'AddSong',
    component: () => import(/* webpackChunkName: "add-song" */ '@/admin/management/AddSong.vue')
  }        
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'text-yellow-400',
  routes
})

export default router
