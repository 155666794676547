<template>
  <div class="navigation">
    <div
      class="py-8 w-full flex items-center md:justify-end justify-center md:border-b-0 border-b border-b-gray-600 md:static fixed top-0 bg-black z-20 text-white"
    >
      <ul class="px-5 flex items-center space-x-5 text-lg uppercase md:mr-10">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <li>
          <router-link to="/songs">Songs</router-link>
        </li>
        <li>
          <router-link to="/past-fourty-eight">Past 48</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
